// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("/opt/build/repo/src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-beef-index-tsx": () => import("/opt/build/repo/src/pages/products/beef/index.tsx" /* webpackChunkName: "component---src-pages-products-beef-index-tsx" */),
  "component---src-pages-products-offal-index-tsx": () => import("/opt/build/repo/src/pages/products/offal/index.tsx" /* webpackChunkName: "component---src-pages-products-offal-index-tsx" */),
  "component---src-pages-products-sheep-index-tsx": () => import("/opt/build/repo/src/pages/products/sheep/index.tsx" /* webpackChunkName: "component---src-pages-products-sheep-index-tsx" */),
  "component---src-pages-terms-of-use-index-tsx": () => import("/opt/build/repo/src/pages/termsOfUse/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

